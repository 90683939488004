import { Icon24UtilityBackArrow, Icon24UtilityNextArrow } from '@care/react-icons';
import React, { useState } from 'react';
import { Box, Link, Theme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import he from 'he';
import sanitizeHtml from 'sanitize-html';
import dateMonthYearFormat from '@/utils/dateMonthYearFormat';
import { SxClassProps } from '@/types/common';
import { trackAmplitudeEvent, AmplitudeEvent, SeoFeatureInteracted } from '@/lib/analyticsHelper';
import { seoArticlesList_seoArticlesList_SEOArticlesListSuccess_seoArticlesList_articles as SEOArticle } from '@/__generated__/seoArticlesList';
import { usePageAttributes } from './contexts/PageAttributesContext';

const classes: SxClassProps = {
  carouselContainer: (theme) => ({
    height: '520px',

    [theme.breakpoints.up('sm')]: {
      height: '600px',
    },
  }),
  header: (theme) => ({
    fontSize: 'h1.fontSize',
    textAlign: 'center',
    padding: theme.spacing(4, 0, 5, 0),
  }),
  carouselWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardsWrapper: (theme) => ({
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    width: '279px',
    '&::MsOverflowStyle': 'none',
    '&::webkitScrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  }),
  card: (theme) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      '&:not(:last-child)': {
        padding: theme.spacing(0, 4, 0, 0),
      },
    },
  }),
  leftArrow: (theme) => ({
    padding: theme.spacing(10, 2, 0, 0),

    '& :hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20, 2, 0, 0),
    },
  }),
  rightArrow: (theme) => ({
    padding: theme.spacing(10, 0, 0, 2),

    '& :hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20, 0, 0, 2),
    },
  }),
  articleHeader: (theme) => ({
    padding: theme.spacing(2, 0, 1),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 0, 1.5),
    },
  }),
  articleDate: {
    fontSize: '1rem',
    color: 'care.grey.400',
  },
  articleContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  articleDescription: {
    fontSize: '.875rem',
  },
  imgContainer: (theme) => ({
    overflow: 'hidden',
    height: '186px',
    width: '279px',

    [theme.breakpoints.only('sm')]: {
      height: '135px',
      width: '200px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '243px',
      width: '365px',
    },
  }),
  imgResponsive: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
};

interface ArticleCarouselProps {
  articles: SEOArticle[];
}

const ArticleCarousel: React.FC<ArticleCarouselProps> = ({ articles }) => {
  const numberOfArticles = articles.length;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const targetValue = isMobileOrSmaller ? '' : '_blank';
  const { segmentNameSingular } = usePageAttributes();

  const [index, setIndex] = useState(0);

  const amplitudeDataRotateArticle: SeoFeatureInteracted = {
    feature_name: 'Rotate Article Carousel',
  };

  const articleAmplitudeData: SeoFeatureInteracted = {
    feature_name: 'Clicked Article Carousel',
  };

  const handleLeftArrow = (): void => {
    if (index === 0) {
      setIndex(numberOfArticles - 1);
    } else {
      setIndex(index - 1);
    }

    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, amplitudeDataRotateArticle);
  };

  const handleRightArrow = (): void => {
    if (index === numberOfArticles - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }

    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, amplitudeDataRotateArticle);
  };

  const renderArticles = () => {
    let currentCountShown = 0;
    let currentIndex = index;
    const currentArticles: SEOArticle[] = [];
    const maxNumOfArticlesToShow = 5;

    while (currentCountShown < maxNumOfArticlesToShow) {
      currentArticles.push(articles[currentIndex]);
      currentIndex += 1;
      currentCountShown += 1;
      if (currentIndex >= numberOfArticles) {
        currentIndex = 0;
      }
    }

    return currentArticles.map(
      ({ title, createDate, description, url, smallImage, largeImage }, idx) => {
        const articleDate =
          createDate !== null
            ? dateMonthYearFormat({
                dateTime: createDate,
                useOrdinalDay: true,
                useLongMonth: true,
                useFullDate: true,
              })
            : '';

        /**
         * @param {string | null} description contains a '<p>' in the beginning of the string
         *  that interfere with the styling layout of the Figma design.
         * @const {string} previewDescription makes a copy of @param {string | null} description without the '<p>'
         */
        const previewDescription =
          description !== null ? description.slice(description.indexOf('>') + 1) : '';
        const imageSourceSmall = smallImage?.imageUrl || '';
        const imageSourceLarge = largeImage?.imageUrl || '';

        return (
          <Box key={`${url}-${idx + 1}`} sx={classes.card}>
            {Boolean(smallImage && largeImage) && (
              <Box sx={classes.imgContainer}>
                <a
                  href={url}
                  target={targetValue}
                  onClick={() =>
                    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, articleAmplitudeData)
                  }
                  data-testid="imageLink">
                  <Box
                    component="img"
                    src={isMobile ? imageSourceSmall : imageSourceLarge}
                    width={isMobile ? '279' : '365'}
                    height={isMobile ? '186' : '243'}
                    alt={title}
                    sx={classes.imgResponsive}
                  />
                </a>
              </Box>
            )}
            <Link
              href={url}
              target={targetValue}
              sx={classes.articleHeader}
              data-testid="article-title-link"
              onClick={() =>
                trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, articleAmplitudeData)
              }>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(he.decode(title)),
                }}
                variant="h2"
                component="h3"
                data-testid="articleTitle"
              />
            </Link>
            <Box>
              <Box component="span" sx={classes.articleDate} data-testid="article-date">
                {articleDate}{' '}
              </Box>
              <Box
                component="span"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(he.decode(previewDescription)),
                }}
                sx={classes.articleDescription}
                data-testid="article-description"
              />
              <span>... </span>
              <span>
                <Link
                  underline="always"
                  href={url}
                  variant="body2"
                  target={targetValue}
                  onClick={() =>
                    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, articleAmplitudeData)
                  }
                  data-testid="readMore">
                  Read more
                </Link>
              </span>
            </Box>
          </Box>
        );
      }
    );
  };

  return (
    <Box sx={classes.carouselContainer}>
      <Typography variant="h2" sx={classes.header}>
        {segmentNameSingular ? `${segmentNameSingular} Articles` : 'Related articles'}
      </Typography>
      <Box sx={classes.carouselWrapper}>
        <Box sx={classes.leftArrow}>
          <Icon24UtilityBackArrow size="20px" data-testid="leftArrow" onClick={handleLeftArrow} />
        </Box>
        <Box sx={classes.cardsWrapper}>{renderArticles()}</Box>
        <Box sx={classes.rightArrow}>
          <Icon24UtilityNextArrow size="20px" data-testid="rightArrow" onClick={handleRightArrow} />
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleCarousel;
